.th_style {
    flex:1;
    height:40px;
    padding-left: 20px;
    font-size: 12px;
    font-weight: unset;
    border-right: 1px solid #f2f4f6;
    color:#627386;
  }

  .td_style {
    flex:1;
    height:40px;
    padding-left: 20px;
    font-size: 14px;
    color:#22272e;
    width:100%;
    overflow-x:auto;
    white-space: nowrap;
    text-transform:capitalize;
  }

  .flex_property{
    display: flex;
    align-items: center;
  }

  .hide{
    display: none;
    cursor:pointer;
  }
  
  .invisible1{
    visibility: hidden;
  }
  
  .display_onhover:hover .invisible1{
    display:flex;
    visibility:visible;
    color: #1890ff;
  }
  
  .display_onhover:hover{
    box-shadow: inset 0 -1px 0 0 #F2F4F6;
    background-color: rgba(24, 144, 255, 0.06) !important;
  }
  
  .underline_onhover:hover {
      text-decoration: underline;
    cursor: pointer;
  }

  .rdt1 {
    width:220px;
    /* height:50px */
  }
  @media (min-width: 768px) {
    .modal-xl {
      width: 90%;
     max-width:1200px;
    }
  }

  ::-webkit-scrollbar {
    height: 0px;
    width: 2px;
  }

